import React, { Component } from 'react';
import SourceEmitter from 'libs/emitter';
import axios from 'axios';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';

import './form.scss';

const initialState = {
  Brand_Opt_Text:
    'Yes, I would like to receive information in the future about NASCOBAL and related health information.',
  Corp_Opt_Text:
    'Yes, I would also like to receive information in the future about Endo products, programs, and services that may be of interest to me.',
  Source: 'sfmc_www.nascobal.com',
  First_Name: '',
  First_Name_Error: true,
  Last_Name: '',
  Last_Name_Error: true,
  Gender: '',
  Email: '',
  Email_Error: true,
  Street_Address: '',
  Street_Address_Error: true,
  Street_Address2: '',
  City: '',
  City_Error: true,
  State: '',
  State_Error: true,
  Zip: '',
  Zip_Error: true,
  From_Doctor: false,
  From_Internet: false,
  From_Relative: false,
  From_Friend: false,
  From_Other: false,
  Other_Sources: '',
  From_Sources_Error: true,
  B12_Deficient: false,
  Taking_B12: false,
  Interested_Reason: '',
  Interested_Reason_Error: true,
  Subscribe_Endo_Products: '',
  FormError: false,
  Environment: process.env.CUSTOM_ENV_VAR,
  checkingReCaptchaForSubmit: false,
  ReCaptchaToken: '',
};

const RECAPTCHA_SITE_KEY = '6Lfnl74dAAAAAGI5DvYmNlaLLsbtWTL2K6HGuqhM';
export default class Form extends Component {
  // Initilaization is necessary for controlled component

  state = initialState;

  componentDidMount() {
    loadReCaptcha(RECAPTCHA_SITE_KEY, () => {
      console.log('loading recaptcha');
    });
  }

  componentWillUnmount() {
    const script = document.getElementById('recaptcha-script');
    if (script) {
      script.remove();
    }

    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
    if (recaptchaElems.length) {
      recaptchaElems[0].remove();
    }
  }

  verifyCallback = async recaptchaToken => {
    console.log(recaptchaToken, "<= your recaptcha token");
    if (this.state.checkingReCaptchaForSubmit) {
      await this.setState({
        checkingReCaptchaForSubmit: false,
        ReCaptchaToken: recaptchaToken,
      });
      await this.handleSubmit();
    }
  };

  updateRecaptchaToken = () => {
    this.recaptcha.execute();
  };

  handleSubmitForCaptcha = async () => {
    await this.setState({ checkingReCaptchaForSubmit: true });
    this.updateRecaptchaToken();
  };

  handleSubmit = () => {
    if (
      this.state.First_Name_Error ||
      this.state.Last_Name_Error ||
      this.state.Email_Error ||
      this.state.Street_Address_Error ||
      this.state.City_Error ||
      this.state.State_Error ||
      this.state.Zip_Error ||
      this.state.From_Sources_Error ||
      this.state.Interested_Reason_Error
    ) {
      this.setState({
        FormError: true,
      });
    } else {
      this.setState({
        FormError: false,
      });

      var heardFrom = '';

      if (this.state.From_Doctor === true) {
        heardFrom += 'Doctor';
      }

      if (this.state.From_Internet === true) {
        if (heardFrom !== '') heardFrom += ',';

        heardFrom += 'Internet';
      }

      if (this.state.From_Relative === true) {
        if (heardFrom !== '') heardFrom += ',';

        heardFrom += 'Relative';
      }

      if (this.state.From_Friend === true) {
        if (heardFrom !== '') heardFrom += ',';

        heardFrom += 'Friend';
      }

      if (this.state.From_Other === true) {
        if (heardFrom !== '') heardFrom += ',';

        heardFrom += this.state.Other_Sources;
      }

      var interestedBecause = '';

      if (this.state.B12_Deficient === true) {
        interestedBecause += 'B12 Deficient';
      }

      if (this.state.Taking_B12 === true) {
        if (interestedBecause !== '') interestedBecause += ',';

        interestedBecause += 'Taking B12 Supplement';
      }

      const dataToSend = {
        Email: this.state.Email,
        FirstName: this.state.First_Name,
        LastName: this.state.Last_Name,
        Address1: this.state.Street_Address,
        Address2:
          this.state.Street_Address2 !== null &&
            this.state.Street_Address2 !== ''
            ? this.state.Street_Address2
            : null,
        City: this.state.City,
        State: this.state.State,
        ZipCode: this.state.Zip,
        Q1: heardFrom,
        Q2: interestedBecause,
        Q3: this.state.Gender,
        BrandOptin: 'Y',
        CorporateOptin: this.state.Subscribe_Endo_Products === true ? 'Y' : 'N',
        CampaignId: null,
        TacticId: null,
        JobId: null,
        SubscriberId: null,
        RecaptchaToken: this.state.ReCaptchaToken,
      };

      console.log(dataToSend);
      // return;

      const registrationEndpoint = process.env.NAS_SFMC_API_ENDPOINT;

      // Submit form
      axios
        .post(registrationEndpoint, dataToSend,
          {
            headers: {
              AuthKey: process.env.NASCOBAL_SFMC_HEADER_VALUE,
            }
          })
        .then(response => {
          // Clear state
          this.setState(initialState);
          SourceEmitter.emit(`FormSubmitted`, true);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  validateCheckboxes = () => {
    if (
      !this.state.From_Doctor &&
      !this.state.From_Internet &&
      !this.state.From_Relative &&
      !this.state.From_Friend &&
      !this.state.From_Other
    ) {
      this.setState({ From_Sources_Error: true });
    } else {
      this.setState({ From_Sources_Error: false });
    }

    if (!this.state.B12_Deficient && !this.state.Taking_B12) {
      this.setState({ Interested_Reason_Error: true });
    } else {
      this.setState({ Interested_Reason_Error: false });
    }
  };

  validateEmail = _email => {
    const emailREGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailREGEX.test(_email);
  };

  validateZip = _zip => {
    const zipREGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    return zipREGEX.test(_zip);
  };

  onChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (target.type !== 'checkbox' && target.required) {
          value.trim() === ''
            ? this.setState({ [`${name}_Error`]: true })
            : this.setState({ [`${name}_Error`]: false });
          if (name === 'Email') {
            const validEmail = this.validateEmail(value);
            validEmail
              ? this.setState({ Email_Error: false })
              : this.setState({ Email_Error: true });
          }
          if (name === 'Zip') {
            const validZIP = this.validateZip(value);
            validZIP
              ? this.setState({ Zip_Error: false })
              : this.setState({ Zip_Error: true });
          }
        } else {
          this.validateCheckboxes();
        }
      },
    );
  };

  render() {
    return (
      <form
        id="signup-form"
        role="form"
        method="POST"
        action=""
        className="signup-form"
        noValidate
      >
        <input
          type="hidden"
          id="Brand_Opt_Text"
          name="Brand_Opt_Text"
          value={this.state.Brand_Opt_Text}
        />
        <input
          type="hidden"
          id="Corp_Opt_Text"
          name="Corp_Opt_Text"
          value={this.state.Corp_Opt_Text}
        />
        <input
          type="hidden"
          id="Source"
          name="Source"
          value={this.state.Source}
        />
        <div
          className={`form-group ${this.state.First_Name_Error && this.state.FormError
            ? 'form-error'
            : ''
            }`}
        >
          <label htmlFor="First_Name">First Name</label>
          <input
            type="text"
            className="form-control"
            id="First_Name"
            name="First_Name"
            value={this.state.First_Name}
            onChange={this.onChange}
            placeholder="First Name"
            required
            maxLength={50}
          />
        </div>
        <div
          className={`form-group ${this.state.Last_Name_Error && this.state.FormError
            ? 'form-error'
            : ''
            }`}
        >
          <label htmlFor="Last_Name">Last Name</label>
          <input
            type="text"
            className="form-control"
            id="Last_Name"
            name="Last_Name"
            value={this.state.Last_Name}
            onChange={this.onChange}
            placeholder="Last Name"
            required
            maxLength={50}
          />
        </div>
        <div className="gender-wrapper">
          <p>Gender (optional)</p>
          <div className="form-group radio-group">
            <input
              type="radio"
              id="male"
              name="Gender"
              value="M"
              checked={this.state.Gender === "M"}
              onChange={this.onChange}
            />
            <label htmlFor="male">Male</label>
            <input
              type="radio"
              id="female"
              name="Gender"
              value="F"
              checked={this.state.Gender === "F"}
              onChange={this.onChange}
            />
            <label htmlFor="female">Female</label>
          </div>
        </div>
        <div
          className={`form-group ${this.state.Email_Error && this.state.FormError ? 'form-error' : ''
            }`}
        >
          <label htmlFor="Email">Email</label>
          <input
            type="email"
            className="form-control"
            id="Email"
            value={this.state.Email}
            onChange={this.onChange}
            name="Email"
            placeholder="Email"
            required
            maxLength={100}
          />
        </div>
        <div
          className={`form-group ${this.state.Street_Address_Error && this.state.FormError
            ? 'form-error'
            : ''
            }`}
        >
          <label htmlFor="Street_Address">Street Address</label>
          <input
            type="text"
            className="form-control"
            id="Street_Address"
            name="Street_Address"
            value={this.state.Street_Address}
            onChange={this.onChange}
            placeholder="Street Address"
            required
            maxLength={100}
          />
        </div>
        <div className="form-group">
          <label htmlFor="Street_Address2">Street Address 2 (optional)</label>
          <input
            type="text"
            className="form-control"
            id="Street_Address2"
            name="Street_Address2"
            value={this.state.Street_Address2}
            onChange={this.onChange}
            placeholder="Street Address 2"
            maxLength={100}
          />
        </div>
        <div
          className={`form-group ${this.state.City_Error && this.state.FormError ? 'form-error' : ''
            }`}
        >
          <label htmlFor="City">City</label>
          <input
            type="text"
            className="form-control"
            name="City"
            id="City"
            value={this.state.City}
            onChange={this.onChange}
            placeholder="City"
            required
            maxLength={50}
          />
        </div>
        <div
          className={`form-group ${this.state.State_Error && this.state.FormError ? 'form-error' : ''
            }`}
          style={{ paddingBottom: 15 }}
        >
          <label htmlFor="State">State</label>
          <select
            id="State"
            name="State"
            className="form-control state"
            onChange={this.onChange}
            required
          >
            <option value="">Select</option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
        </div>
        <div
          className={`form-group ${this.state.Zip_Error && this.state.FormError ? 'form-error' : ''
            }`}
        >
          <label htmlFor="Zip">ZIP</label>
          <input
            type="text"
            className="form-control"
            id="Zip"
            name="Zip"
            value={this.state.Zip}
            onChange={this.onChange}
            placeholder="ZIP"
            required
            maxLength={5}
          />
        </div>
        <div className="hear-source-wrapper">
          <p>
            <span className="strong">
              How did you hear about NASCOBAL
              <sup className="brand-reg-mark">&reg;</sup>?
            </span>{' '}
            (check all that apply)
          </p>
          <div
            className={`form-group checkbox-group ${this.state.From_Sources_Error && this.state.FormError
              ? 'form-error'
              : ''
              }`}
          >
            <div className="checkbox">
              <input
                type="checkbox"
                className="form-control"
                name="From_Doctor"
                id="Doctor"
                onChange={this.onChange}
              />
              <label htmlFor="Doctor">Doctor</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                className="form-control"
                name="From_Internet"
                id="Internet"
                onChange={this.onChange}
              />
              <label htmlFor="Internet">Internet</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                className="form-control"
                name="From_Relative"
                id="Relative"
                onChange={this.onChange}
              />
              <label htmlFor="Relative">Relative</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                className="form-control"
                name="From_Friend"
                id="Friend"
                onChange={this.onChange}
              />
              <label htmlFor="Friend">Friend</label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                className="form-control"
                name="From_Other"
                id="Other"
                onChange={this.onChange}
              />
              <input
                type="text"
                className="form-control"
                id="other-hear-source"
                // eslint-disable-next-line no-unneeded-ternary
                disabled={this.state.From_Other !== '' ? false : true}
                name="Other_Sources"
                value={this.state.Other_Sources}
                onChange={this.onChange}
                placeholder="Other"
                style={{ maxWidth: 300 }}
                maxLength={100}
              />
            </div>
          </div>
        </div>
        <div className="interested-reason-wrapper">
          {' '}
          <p>
            <span className="strong">
              Why are you interested in NASCOBAL
              <sup className="brand-reg-mark">&reg;</sup>?
            </span>{' '}
            (check all that apply)
          </p>
          <div
            className={`form-group checkbox-group ${this.state.Interested_Reason_Error && this.state.FormError
              ? 'form-error'
              : ''
              }`}
          >
            <div className="checkbox">
              <input
                type="checkbox"
                className="form-control"
                id="b12-deficient"
                value="My healthcare provider suggested that I am deficient in B12"
                name="B12_Deficient"
                onChange={this.onChange}
              />
              <label htmlFor="b12-deficient">
                My healthcare provider suggested that I am deficient in vitamin
                B<sub>12</sub>{' '}
              </label>
            </div>
            <div className="checkbox">
              <input
                type="checkbox"
                className="form-control"
                id="taking-b12"
                name="Taking_B12"
                value="I am currently taking a B12 supplement"
                onChange={this.onChange}
              />
              <label htmlFor="taking-b12">
                I am currently taking a vitamin B<sub>12</sub> supplement
              </label>
            </div>
          </div>
        </div>
        <div className="" style={{ paddingTop: 20 }}>
          <div className="form-group">
            <div>
              <p htmlFor="subscribe-nascobal" style={{ paddingLeft: 0 }}>
                <strong>Yes,</strong> I would like to receive information in the
                future about NASCOBAL
                <sup className="brand-reg-mark">&reg;</sup> and related health
                information.
              </p>
            </div>
            <div className="checkbox" style={{ marginTop: 20 }}>
              <input
                type="checkbox"
                className="form-control"
                id="subscribe_endo_products"
                name="Subscribe_Endo_Products"
                onChange={this.onChange}
              />
              <label
                htmlFor="subscribe_endo_products"
                style={{ display: 'inline' }}
              >
                <strong style={{ display: 'inline' }}>Yes,</strong>
                I would also like to receive information in the future about
                Endo products, programs, and services that may be of interest to
                me.
              </label>
            </div>
          </div>
        </div>
        <div>
          <p style={{ paddingTop: 25, paddingLeft: 0 }}>
            Endo Pharmaceuticals Inc. understands that your privacy is
            important. By providing your name, address, and other requested
            information, you are giving Endo Pharmaceuticals Inc. and other
            parties working with us permission to communicate with you about
            NASCOBAL
            <sup className="brand-reg-mark">&reg;</sup> or other products,
            services, and offers from Endo Pharmaceuticals Inc. We will not sell
            your name or other personal information to any party for its
            marketing use. To view the privacy policy, please visit {' '}
            <a
              href="https://www.endo.com/privacy-legal"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#005495' }}
            >
              www.endo.com/privacy-legal
            </a>
            .
          </p>
        </div>
        <div>
          <button
            type="button"
            // onClick={this.handleSubmit}
            onClick={this.handleSubmitForCaptcha}
            className="nascobal-btn sign-up-form-pat"
          >
            SUBMIT
          </button>
          <ReCaptcha
            ref={ref => (this.recaptcha = ref)}
            sitekey={RECAPTCHA_SITE_KEY}
            verifyCallback={this.verifyCallback}
          />
        </div>
      </form>
    );
  }
}
